<template>
  <div id="app">
<!--    <div @click="goback()" class="topnav2" id="vtopnav">
      <p style="width: 100%">
        <i class="left"></i>
        <span
          style="
            font-size: 17px !important;
            display: block !important;
            line-height: 15px !important;
            text-align: center !important;
            width: 100% !important;
            font-weight: 500 !important;
          "
          >说明书</span
        >
      </p>
    </div>-->
    <div id="demo" ></div>
  </div>
</template>
<script>
// import Pdfh5 from "pdfh5";
import util from '../util'
// import "pdfh5/css/pdfh5.css";
import TopNav from "../components/TopNav.vue";
export default {
  name: "App",
  components: {
    TopNav,
  },
  data() {
    return {
      pdfh5: null,
    };
  },
  mounted() {
    let url = util.getQueryVariable('url');
    url = unescape(url)
    console.log('urlaa:', url);
    //实例化
    this.pdfh5 = new Pdfh5("#demo", {
      pdfurl: url,
      scale: 4,
    });
    //监听完成事件
    this.pdfh5.on("complete", function (status, msg, time) {
      console.log(
        "状态：" +
          status +
          "，信息：" +
          msg +
          "，耗时：" +
          time +
          "毫秒，总页数：" +
          this.totalNum
      );
    });
    // let topnavheight = document.querySelector("#vtopnav");
    // topnavheight.style.height = this.$route.params.height + "px";
    // let pdfjs = document.querySelector(".pdfjs");
    // pdfjs.style.paddingTop = this.$route.params.height + 10 + "px";
    // this.countNum();
  },
  methods: {
    goback() {
      this.$store.state.statuscode = 1;
      this.$router.go(-1);
    },
    async countNum() {
      try {
        if (this.$store.state.mode == "EC2-G") {
          this.$store.state.mode = "EC2";
        }
        let res = await this.put("/videoResource/updateManualViewCount", {
          mode: this.$store.state.mode,
        });
      } catch (err) {
        console.log("err", err);
      }
    },
  },
};
</script>
<style>
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  display: flex;
  flex-direction: column;
}
.topnav2 {
  display: flex !important;
  align-items: flex-end;
  position: fixed;
  z-index: 900;
  width: 100%;
}
.left {
  border: solid black !important;
  border-width: 0 1.9px 1.9px 0 !important;
  display: inline-block !important;
  padding: 7px !important;
  transform: rotate(135deg) !important;
  -webkit-transform: rotate(135deg) !important;
  margin-left: 28px !important;
  position: absolute;
}
.pdfjs .pageNum{
  left: auto;
  right: 20px !important;
}
</style>
