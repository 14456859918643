<template>
  <div @click="goback(data.type)" class="topnav">
    <!-- <img src="@/assets/fh.png" alt="" /> -->
    <i @click="goback()" class="left"></i>
    <span
      style="
        font-size: 17px;
        display: block;
        line-height: 12px;
        text-align: center;
        width: 100%;
        font-weight: 500;
      "
      >{{ data.title }}</span
    >
  </div>
</template>

<script>
import util from "../common/util";
export default {
  name: "Login",
  props: ["data"],
  data() {
    return {};
  },
  components: {},
  mounted() {
    let timer = null;
    setTimeout(() => {
      this.sizeadapt();
    }, 500);
    window.onresize = () => {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        this.sizeadapt();
      }, 500);
    };
  },
  methods: {
    sizeadapt() {
      let designSize = 750; // 设计图尺寸
      // 浏览器宽度
      let rem = (document.documentElement.clientWidth * 100) / designSize;
      document.documentElement.style.fontSize = rem + "px";
      return rem;
    },
    goback(type) {
      if (type === "question" || type === "userManual") {
        //用户手册，常见问题
        util.goback();
      } else if (type === "goOperate") {
        //骑行操作，新车组装
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.topnav {
  //height: 2.2rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;

  img {
    transform: scale(0.25);
    position: absolute;
    left: -0.5rem;
  }
  .left {
    border: solid #282828;
    border-width: 0 1.9px 1.9px 0;
    display: inline-block;
    padding: 7px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-left: 20px;
    position: absolute;
  }
}
</style>
