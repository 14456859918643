class Util {
  isEmpty(v) {
    switch (typeof v) {
      case "undefined":
        return true;
      case "string":
        if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, "").length == 0)
          return true;
        break;
      case "boolean":
        if (!v) return true;
        break;
      case "number":
        if (0 === v || isNaN(v)) return true;
        break;
      case "object":
        if (null === v || v.length === 0) return true;
        for (var i in v) {
          return false;
        }
        return true;
    }
    return false;
  }
  updateTree(trees, map) {
    console.log("bTree:", trees);
    for (let item of trees) {
      this.updateItem(item, map);
    }
    console.log("eTree:", trees);
    return trees;
  }
  updateItem(item, map) {
    for (let key of map.keys()) {
      item[map.get(key)] = item[key].toString();
      item["opened"] = false;
      item["selected"] = false;
      console.log("id:", item.id);
      if (item.id > 0) {
        item["icon"] = "iconfont icon-wode1";
      } else {
        item["icon"] = "iconfont icon-Tree";
      }
    }

    if (item.children) {
      for (let child of item.children) {
        this.updateItem(child, map);
      }
      item.isLeaf = false;
    } else {
      item.isLeaf = true;
    }
  }
  //根据路径获取序列号
  getQueryVariable(variable) {
    let idx = window.location.href.indexOf("?"); //获取当前路径
    var query = window.location.href.substring(idx + 1); //截取?后面的字符串
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  gettopheight(value) {
    let addr = new URL(window.location.href);
    console.log(9999, addr.searchParams.get(value));
    return addr.searchParams.get(value);
  }
  // goback() {
  //   var u = navigator.userAgent;
  //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  //   if (isiOS) {
  //     window.webkit.messageHandlers.callBackAction.postMessage("hh");
  //   } else {
  //     messageHandlers.callBackAction();
  //   }
  // }
}

export default new Util();
